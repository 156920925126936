import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  noWarning: boolean;
  noCodeWarning: boolean;
  noMemberWarning: boolean;
  noStoreWarning: boolean;
  noRegisterWarning: boolean;
  noProductWarning: boolean;
  noErrorWarning: boolean;
  noSuccessWarning: boolean;
  noLoadingWarning: boolean;

  constructor(private http: HttpClient) {
    this.noWarning = true;
    this.noCodeWarning = true;
    this.noMemberWarning = true;
    this.noStoreWarning = true;
    this.noRegisterWarning = true;
    this.noProductWarning = true;
    this.noErrorWarning = true;
    this.noSuccessWarning = true;
    this.noLoadingWarning = true;
  }

  ngOnInit(): void {
    window['dataLayer'] = window['dataLayer'] || {};
  }

  showWarning(warning) {
    this.noWarning = false;
    switch (warning) {
      case 'code': {
        this.noCodeWarning = false;
        break;
      }
      case 'loading': {
        this.noLoadingWarning = false;
        break;
      }
      case 'member': {
        this.noMemberWarning = false;
        break;
      }
      case 'store': {
        this.noStoreWarning = false;
        break;
      }
      case 'product': {
        this.noProductWarning = false;
        break;
      }
      case 'error': {
        this.noErrorWarning = false;
        break;
      }
      case 'register': {
        this.noRegisterWarning = false;
        break;
      }
      case 'success': {
        this.noSuccessWarning = false;
        break;
      }
    }
  }

  closeWarning() {
    this.noWarning = true;
    this.noCodeWarning = true;
    this.noMemberWarning = true;
    this.noStoreWarning = true;
    this.noRegisterWarning = true;
    this.noProductWarning = true;
    this.noErrorWarning = true;
    this.noSuccessWarning = true;
    this.noLoadingWarning = true;
  }

  handleSubmit(event) {
    this.showWarning('loading');
    event.preventDefault();
    let req = {
      name: '',
      email: '',
      phone: '',
      device_id: '',
    };
    req.name = event.target.name.value;
    req.email = event.target.email.value;
    req.phone = event.target.phone.value;
    req.device_id = event.target.device_id.value;
    this.sendForm(req).subscribe(
      (resp) => {
        this.closeWarning();
        window['dataLayer'].push({
          event: 'generate_lead',
          email: req.email,
          phone: req.phone,
          currency: 'MXN',
          value: 1,
          socio_comercial: 'pmi',
          marca: 'iqos_iluma',
        });
        this.showWarning('success');
      },
      (err) => {
        this.closeWarning();
        switch (err.error.message) {
          case 'Member not found': {
            window['dataLayer'].push({
              event: 'disqualify_lead',
              email: req.email,
              phone: req.phone,
              currency: 'MXN',
              value: 1,
              socio_comercial: 'pmi',
              marca: 'iqos_iluma',
              disqualified_lead_reason: 'usuario_no_registrado',
            });
            this.showWarning('member');
            break;
          }
          case 'Device id not found in db': {
            window['dataLayer'].push({
              event: 'disqualify_lead',
              email: req.email,
              phone: req.phone,
              currency: 'MXN',
              value: 1,
              socio_comercial: 'pmi',
              marca: 'iqos_iluma',
              disqualified_lead_reason: 'producto_no_comprado_oxxo',
            });
            this.showWarning('store');
            break;
          }
          case 'Member id found in DB': {
            window['dataLayer'].push({
              event: 'disqualify_lead',
              email: req.email,
              phone: req.phone,
              currency: 'MXN',
              value: 1,
              socio_comercial: 'pmi',
              marca: 'iqos_iluma',
              disqualified_lead_reason: 'usuario_ya_redimio',
            });
            this.showWarning('register');
            break;
          }
          case 'Device id found in DB with another member id': {
            this.showWarning('product');
            break;
          }
          default:
            this.showWarning('error');
            break;
        }
      }
    );
  }

  sendForm(data: any): Observable<any> {
    return this.http.post(
      `https://oxxo-api-l6dmrzkz7a-uc.a.run.app/api/v1/records`,
      data
    );
  }
}
